<template>
  <div>
    <div class="bg-kct-green h-0.5"></div>
    <div
      class="bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/main-banner.jpg')"
    >
      <div class="container">
        <div class="py-20 lg:flex lg:items-center">
          <div class="lg:w-1/2 lg:text-left lg:pr-2">
            <h1
              class="pb-3 text-3xl font-bold leading-tight  md:text-4xl lg:text-5xl text-kct-green"
            >
              35 YEARS OF MANUFACTURING EXCELLENCE
            </h1>
            <h1 class="text-base leading-tight text-gray-800">
              Your One-Stop Manufacturer Solutions The More Complex The
              Customization The More We Love It!
            </h1>
            <img
              src="/images/made-in-malaysia.png"
              alt=""
              class="w-24 mx-auto lg:mx-0 lg:w-32"
            />
            <!-- cta enquire -->
            <div class="pt-3">
              <a
                href="#element"
                v-if="!hideEnquiry"
                v-scroll-to="{ el: '#element', duration: 1500 }"
              >
                <h1
                  class="w-48 py-2 mx-auto text-center text-white bg-gray-800  lg:mx-0 hover:opacity-100 opacity-90"
                >
                  Enquire Us
                </h1>
              </a>
            </div>
          </div>
          <div class="lg:w-1/2">
            <img
              src="/images/banner_bag.png"
              alt=""
              class="md:w-2/3 lg:w-full md:mx-auto"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>