<template>
  <header class="py-3">
    <div class="container">
      <div class="md:flex md:justify-between lg:items-center">
        <img
          src="/images/main-logo.png"
          alt="KCT United Sdn Bhd"
          class="mx-auto md:mx-0 w-60 md:w-36 lg:w-44"
        />
        <div class="pt-3">
          <a href="tel:+6166336833" id="cta-call-top">
            <h1
              class="py-2 mx-auto text-lg font-medium transition duration-300 ease-linear border-2  md:w-60 md:px-2 hover:text-white border-kct-green hover:bg-kct-green w-52"
            >
              Call Us : 016-6336833
            </h1>
          </a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {};
</script>

<style>
</style>