<template>
  <div>
    <!-- main header -->
    <MainHeader />

    <!-- main banner -->
    <MainBanner />

    <!-- about us -->
    <div class="py-10">
      <div class="container">
        <h1 class="text-base text-gray-600">About Us</h1>
        <h1 class="pb-3 text-xl font-semibold text-kct-green">
          KCT Union Sdn Bhd
        </h1>
        <div class="bg-kct-green h-0.5 w-10 mx-auto"></div>
        <p
          class="pt-3 text-sm font-light text-gray-600 lg:max-w-3xl lg:mx-auto"
        >
          We established since 1984 and we are the specialist in the
          manufacturing of bags and baggage products. Since the establishment,
          we have been exporting 20% of our production overseas and the balance
          80% cater for the local market.
        </p>
        <!-- content -->
        <div class="pt-6 lg:flex lg:items-center">
          <div class="lg:w-1/2">
            <img src="/images/about-us.jpg" alt="" class="mx-auto" />
          </div>
          <div class="pt-3 lg:pt-0 lg:text-left lg:pl-4 lg:w-1/2">
            <h1 class="pb-2 text-base font-semibold">Our Showroom:</h1>
            <p class="pb-4 text-sm font-light text-gray-600">
              We guarantee that our clients are ensured the finest quality and
              the best service. With more than 35 years of experience and good
              quality of services, we are now well known in local market and
              have a very good reputation in Advertising, Event and Promotion
              industry especially in the Direct Sales and Multi-level Marketing
              companies.
            </p>
            <h1 class="pb-2 text-base font-semibold">Our Factory:</h1>
            <p class="text-sm font-light text-gray-600">
              Our products undergo with different technologies, methods and
              stringent test to ensure consistent quality and fine products. Our
              products are of superior quality as customer satisfaction is our
              main interest. The materials used in our products are mainly nylon
              (specially for sport bags), synthetic leather, PU, genuine
              leather, PVC, PP and many other kinds of material.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- services section -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <h1 class="text-base text-gray-600">What We Do</h1>
        <h1 class="pb-3 text-xl font-semibold leading-tight text-kct-green">
          Customize &amp; Manufacturing in All Types of Premium Gift Supplier
        </h1>
        <div class="bg-kct-green h-0.5 w-10 mx-auto"></div>
        <p
          class="pt-3 text-sm font-light text-gray-600 lg:max-w-3xl lg:mx-auto"
        >
          We aspire to provide clients with the finest quality and best service.
          With more than 25 years of manufacturing experience, we have carved a
          reputation for good service in the local Advertising, Event, and
          Promotion Industry - especially among Direct Sales and Multi-level
          Marketing companies. Backed by top-class expertise and credentials, we
          ensure that every KCT product provides total satisfaction for
          customers
        </p>

        <!-- content -->
        <div class="flex flex-wrap pt-6 lg:mx-auto lg:max-w-3xl">
          <div v-for="(item, i) in service" :key="i" class="w-1/2 p-2 lg:w-1/3">
            <img :src="item.image" :alt="item.alt" class="w-24 mx-auto" />
            <h1 class="pt-2 text-sm font-medium text-kct-green">
              {{ item.alt }}
            </h1>
          </div>
        </div>
        <p class="pt-4 text-sm font-light text-gray-600">Ultimately, our established name in the market speaks for itself.</p>
      </div>
    </div>

    <!-- product section -->
    <div class="py-10">
      <div class="container">
        <h1 class="pb-3 text-xl font-semibold leading-tight text-kct-green">
          Our Products
        </h1>
        <div class="bg-kct-green h-0.5 w-10 mx-auto"></div>
        <p class="pt-3 text-sm font-light text-gray-600">
          We manufacture, import and sell the following three (3) main
          categories of item:
        </p>

        <!-- content -->
        <div class="pt-6 lg:flex lg:flex-wrap">
          <div
            v-for="(item, i) in product"
            :key="i"
            class="pb-2 lg:px-1 lg:w-1/3"
          >
            <div class="border-2 border-kct-green">
              <h1
                class="py-3 text-base font-semibold text-white uppercase bg-kct-green"
              >
                {{ item.h1 }}
              </h1>
              <div class="lg:h-96 xl:h-80">
                <ul
                  class="flex flex-wrap py-5 pl-6 text-left list-disc list-outside "
                >
                  <li
                    v-for="sub in item.list"
                    :key="sub"
                    class="w-1/2 px-2 text-xs"
                  >
                    {{ sub }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div class="py-10" style="background-image: url('/images/bg-choose.jpg')">
      <div class="container">
        <h1 class="pb-3 text-xl font-semibold leading-tight text-white">
          Why Choose Us
        </h1>
        <div class="bg-white h-0.5 w-10 mx-auto"></div>
        <!-- content -->
        <div class="flex flex-wrap pt-6 lg:max-w-3xl lg:mx-auto">
          <div
            v-for="(item, i) in why_choose_us"
            :key="i"
            class="w-1/2 p-1 lg:w-1/4"
          >
            <img :src="item.image" :alt="item.alt" class="w-20 mx-auto" />
            <h1 class="pt-3 text-base font-semibold text-white">
              {{ item.alt }}
            </h1>
          </div>
        </div>
        <div class="pt-4 lg:pt-6">
          <a href="https://www.kctunion.com/index.php?ws=category" target="_blank" id="cta-call-middle">
            <h1
              class="p-2 text-lg font-semibold text-white transition duration-300 ease-in border-2 border-white lg:py-3 lg:mx-auto lg:w-2/3 hover:bg-white hover:text-kct-green"
            >
              View Here For More Products
            </h1>
          </a>
        </div>
      </div>
    </div>

    <!-- enquire form -->
    <div id="element" class="py-10 bg-kct-green">
      <div class="container">
        <h1 class="pb-3 text-xl font-semibold leading-tight text-white">
          Enquire Us Now
        </h1>
        <div class="bg-white h-0.5 w-10 mx-auto"></div>
        <p class="pt-3 text-sm font-light text-white">
          Fill out the form below and we'll get back to you as soon as possible
        </p>

        <div class="pt-6 lg:max-w-3xl lg:mx-auto">
          <!-- feedback.activamedia.com.sg script begins here --><iframe
            allowTransparency="true"
            style="min-height: 520px; height: inherit; overflow: auto"
            width="100%"
            id="contactform123"
            name="contactform123"
            marginwidth="0"
            marginheight="0"
            frameborder="0"
            src="https://feedback.activamedia.com.sg/my-contact-form-5232695.html"
            ><p>
              Your browser does not support iframes. The contact form cannot be
              displayed. Please use another contact method (phone, fax etc)
            </p></iframe
          ><!-- feedback.activamedia.com.sg script ends here -->
        </div>
      </div>
    </div>

    <!-- footer -->
    <footer class="py-10">
      <div class="container">
        <h1 class="pb-3 text-xl font-semibold leading-tight text-kct-green">
          Our Location
        </h1>
        <div class="bg-kct-green h-0.5 w-10 mx-auto"></div>

        <div class="pt-6 lg:flex lg:items-center">
          <div class="pb-3 lg:text-left lg:pl-3 lg:w-1/2">
            <img
              src="/images/main-logo.png"
              alt="KCT Union Sdn Bhd"
              class="mx-auto w-52 lg:w-72 lg:mx-0"
            />
            <h1
              class="pt-4 text-base font-semibold leading-snug text-kct-green"
            >
              Lot 212, Batu 6 ½, Jinjang West Ind Area, Jalan Kepong, 52000
              Kuala Lumpur, Malaysia.
            </h1>
            <div class="pt-3">
              <h1 class="text-base font-semibold text-gray-600">
                <i class="pr-2 fas fa-mobile-alt"></i>Call Us:
                <a href="tel:+60166336833" class="hover:text-kct-green"
                  >016-6336833</a
                >
              </h1>
            </div>
          </div>

          <div class="lg:w-1/2">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7967.106141853558!2d101.654258!3d3.211346!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9ce053ef0c6b8728!2sKCT%20Enterprise%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1639376768635!5m2!1sen!2smy"
              width="100%"
              height="300"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </footer>

    <div class="py-3 bg-kct-green">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MainHeader from "@/components/MainHeader.vue";
import MainBanner from "@/components/MainBanner.vue";

export default {
  name: "Home",
  components: {
    MainHeader,
    MainBanner,
  },
  data() {
    return {
      service: [
        { image: "/images/service-1.png", alt: "Corporate Gifts" },
        { image: "/images/service-2.png", alt: "Brand Merchandising" },
        { image: "/images/service-3.png", alt: "Client Appreciation" },
        { image: "/images/service-4.png", alt: "Employees Benefits" },
        { image: "/images/service-5.png", alt: "Conference & Events" },
        { image: "/images/service-6.png", alt: "Celebrations & Holidays" },
      ],
      product: [
        {
          h1: "Bags",
          list: [
            "Backpack",
            "School Bag",
            "Sport Bag",
            "Travel Bag",
            "Laptop Bag",
            "Trolley Luggage Bag",
            "Laptop Trolley Bag",
            "Hand Luggage Bag",
            "Sling Bag",
            "Shoe Bag",
            "Goftset Bag",
            "Document Bag",
            "Shopping Bag ",
            "Non-woven Bag",
            "Children Tuition Bag",
            "Cosmetic Bag",
            "Cross-over Bag",
            "Cooler Bag",
            "Foldable Bag & etc",
            "Cosmetic Pouch",
            "Make Up Pouch",
            "Medical Pouch",
            "First Aid Kit",
          ],
        },
        {
          h1: "Leather, PU & PVC Goods",
          list: [
            "Diaries",
            "Organizer",
            "Sales Starter Kit",
            "Presentation Kit",
            "Notebook Holder",
            "Notepad Holder",
            "Memo Folder",
            "Signature Folder",
            "Ring File",
            "Menu Folder",
            "Executive Planner",
            "Name Tag Holder",
            "Travel Tag",
            "Gift Box",
            "Photo Album",
            "Keychain",
            "Keychain Holder",
            "Cheque Book Holder",
            "Passport Travel Holder",
            "Name / Credit Card Holder",
            "Pen Holder",
            "Letter Holder ",
            "CD Holder",
            "Dashboard Holder",
            "Mouse Pad",
            "Desktop Holder",
            "Desktop Planner",
            "Cosmetic Packaging",
            "Toiletries PVC Bag",
            "Handphone Holder",
            "Waist Pouch",
            "Pouch",
            "Wallet",
            "Car Boot Organiser & etc",
          ],
        },
        {
          h1: "Premium Gifts and Others",
          list: [
            "T-shirt",
            "Uniform",
            "Vest",
            "Jacket",
            "Metal Cosmetic Showcase",
            "Pens",
            "Pencil Case",
            "Stationery Set",
            "Pill Box",
            "Umbrella",
            "Thermal Flask",
            "Wooden Business Card Holder with Pen",
            "Wooden Pen Holder",
            "Crystal Photo Frame",
            "Mooncake Box",
            "Cap",
            "Sunglass",
            "Splading Watch",
            "Wine Holder",
            "Magnetic Bookmark",
            "Goft Tools Set with Pouch",
            "Capsule Calender Clock",
            "Crystal",
            "Crystal Trophy",
            "Universal Travel Adapter",
            "Wooden Box",
            "Plastic Casr",
            "Metal Keychain",
            "Rubberize Products",
          ],
        },
      ],
      why_choose_us: [
        {
          image: "/images/choose-1.png",
          alt: "Malaysia Factory",
        },
        {
          image: "/images/choose-2.png",
          alt: " Site Visit Available",
        },
        {
          image: "/images/choose-3.png",
          alt: "Free Consultation",
        },
        {
          image: "/images/choose-4.png",
          alt: " Premium Quality",
        },
      ],
    };
  },
};
</script>

<style>
.txt-shadow {
  text-shadow: 2px 2px 3px #313131;
}
</style>
